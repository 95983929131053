@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap");

.App {
  font-family: sans-serif;
}

.dropdown {
  position: relative;
  color: #333;
  cursor: default;
}

.dropdown .selected-value input {
  line-height: 1.5;
  font-size: 1rem;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 2px;
  box-sizing: border-box;
  cursor: default;
  outline: none;
  padding: 8px 52px 8px 10px;
  transition: all 200ms ease;
  width: 100%;
}

.options {
  display: none;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-top: -17px;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  width: 96%;
  z-index: 1000;
  /*-webkit-overflow-scrolling: touch;*/
}

.open {
  display: block;
}

.dropdown .option {
  box-sizing: border-box;
  color: rgba(51, 51, 51, 0.8);
  cursor: pointer;
  display: block;
  padding: 8px 10px;
}

div[role="menubar"] {
  top: 100px !important;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #7dd3fc;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #7dd3fc;
}

/* Logo Google */
div[style*="z-index: 1000000;"] {
  left: 48% !important;
  bottom: 10px !important;
}

@media only screen and (max-width: 640px) {
  div[style*="z-index: 1000000;"] {
    left: 0 !important;
    bottom: 0px !important;
  }
}

*.unselectable {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.unselectable {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Curved text */
textPath {
  background-color: #333;
  /* font-family: 'Luckiest Guy'; */
  font-size: 45px;
}

#curve {
  fill: transparent;
}

text {
  fill: black;
}

span:nth-child(6)::after {
  content: "\A";
  white-space: pre;
}

.svg-div {
  width: 300px;
  height: 300px;
  overflow: hidden;
}

/* InfoWindow */
.gm-style .gm-style-iw-c {
  padding-right: 10px !important;
  padding-bottom: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 0px !important;
  /* width: 400px !important; */
  width: 240px !important;
  /* height: 300px !important; */
  height: 160px !important;
  position: absolute;
  box-sizing: border-box;
  overflow: visible;
  /* top: -53px; */
  top: 43px;
  left: 0;
  transform: translate(-50%, -85%);
  background-color: transparent !important;
  /* background-color: black !important; */
  /* border-radius: 100% !important; */
  border-radius: 6px !important;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.2);
  /* box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.2); */
  /* transition: all 0.3s ease-out !important; */
}

.gm-style-iw-tc {
  transform: translate(-50%, 40000px) !important;
  background: transparent;
}

.gm-style-iw-d {
  overflow: hidden !important;
}

.gm-ui-hover-effect {
  display: none !important;
}
/* InfoWindow */

/***** Pins *****/
/*
 * Property styles in unhighlighted state.
 */
.property {
  align-items: center;
  background-color: #ffffff;
  border-radius: 50%;
  color: #263238;
  display: flex;
  font-size: 14px;
  gap: 15px;
  height: 30px;
  justify-content: center;
  padding: 4px;
  position: relative;
  position: relative;
  transition: all 0.3s ease-out;
  width: 30px;
}

.property::after {
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-top: 9px solid #ffffff;
  content: "";
  height: 0;
  left: 50%;
  position: absolute;
  top: 95%;
  transform: translate(-50%, 0);
  transition: all 0.3s ease-out;
  width: 0;
  z-index: 1;
}

.property .icon {
  align-items: center;
  display: flex;
  justify-content: center;
  color: #ffffff;
}

.property .icon svg {
  height: 20px;
  width: auto;
}

.property .details {
  display: none;
  flex-direction: column;
  flex: 1;
}

.property .address {
  color: #9e9e9e;
  font-size: 10px;
  margin-bottom: 10px;
  margin-top: 5px;
}

.property .features {
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.property .features > div {
  align-items: center;
  background: #f5f5f5;
  border-radius: 5px;
  border: 1px solid #ccc;
  display: flex;
  font-size: 10px;
  gap: 5px;
  padding: 5px;
}

/********/
.shake {
  animation: shake 2s ease infinite;
}
@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateX(-5px);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translateX(5px);
  }
}

/****CircleMenu****/
.wrapper {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  user-select: none !important;
}

.defaultWrapper {
  width: 400px;
  height: 400px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  user-select: none !important;
}

.wrapper .sector {
  position: absolute;
  transform-origin: 100% 100%;
  width: 300px;
  height: 300px;
  left: 50%;
  top: 50%;
  margin-top: -300px;
  margin-left: -300px;
}

.defaultWrapper .sector {
  position: absolute;
  transform-origin: 100% 100%;
  width: 300px;
  height: 300px;
  left: 50%;
  top: 50%;
  margin-top: -300px;
  margin-left: -300px;
}

.wrapper .sector {
  position: absolute;
  transform-origin: 100% 100%;
  width: 300px;
  height: 300px;
  left: 50%;
  top: 50%;
  margin-top: -300px;
  margin-left: -300px;
}

.wrapper .sector3 {
  position: absolute;
  transform-origin: 100% 100%;
  width: 300px;
  height: 300px;
  left: 50%;
  top: 50%;
  margin-top: -300px;
  margin-left: -300px;
}

.wrapper .sector6 {
  position: absolute;
  transform-origin: 100% 100%;
  width: 300px;
  height: 300px;
  left: 50%;
  top: 50%;
  margin-top: -300px;
  margin-left: -300px;
}

.wrapper .sector5 {
  position: absolute;
  transform-origin: 100% 100%;
  width: 300px;
  height: 300px;
  left: 50%;
  top: 50%;
  margin-top: -300px;
  margin-left: -300px;
}

.wrapper .sector73 {
  position: absolute;
  transform-origin: 100% 100%;
  width: 300px;
  height: 300px;
  left: 50%;
  top: 50%;
  margin-top: -300px;
  margin-left: -300px;
}

.wrapper .sector75 {
  position: absolute;
  transform-origin: 100% 100%;
  width: 300px;
  height: 300px;
  left: 50%;
  top: 50%;
  margin-top: -300px;
  margin-left: -300px;
}

.wrapper .sector74 {
  position: absolute;
  transform-origin: 100% 100%;
  width: 300px;
  height: 300px;
  left: 50%;
  top: 50%;
  margin-top: -300px;
  margin-left: -300px;
}

.wrapper .sector76 {
  position: absolute;
  transform-origin: 100% 100%;
  width: 300px;
  height: 300px;
  left: 50%;
  top: 50%;
  margin-top: -300px;
  margin-left: -300px;
}

@media only screen and (max-width: 640px) {
  .wrapper {
    width: 100%;
    height: 100%;
    border-radius: 0%;
  }

  .defaultWrapper {
    width: 100%;
    height: 100%;
    border-radius: 0%;
  }

  .wrapper .sector {
    left: 50%;
    top: 0%;
    height: 50%;
    margin-left: -300px;
    margin-top: 0px;
  }

  .wrapper .sector6 {
    left: -8%;
    top: 0%;
    height: 50%;
    width: 550px;
    margin-left: -300px;
    margin-top: 0px;
  }

  .wrapper .sector5 {
    left: 20%;
    top: 0%;
    height: 50%;
    width: 400px;
    margin-left: -300px;
    margin-top: 0px;
  }

  .wrapper .sector73 {
    left: 50%;
    top: -50px;
    height: 60%;
    margin-left: -300px;
    margin-top: 0px;
  }

  .wrapper .sector74 {
    left: 45%;
    top: 10px;
    height: 50%;
    width: 371px;
    margin-left: -300px;
    margin-top: 0px;
  }

  .wrapper .sector75 {
    left: 10%;
    top: 0%;
    height: 50%;
    width: 450px;
    margin-left: -300px;
    margin-top: 0px;
  }

  .wrapper .sector76 {
    left: 20%;
    top: -30px;
    height: 60%;
    width: 420px;
    margin-left: -300px;
    margin-top: 0px;
  }

  @media only screen and (max-width: 431px) {
    .div73 {
      position: absolute;
      top: 80%;
      left: 35%;
    }

    .div75 {
      position: absolute !important;
      top: 69% !important;
      left: 35% !important;
    }

    .div76 {
      position: absolute;
      top: 75%;
      left: 60%;
    }

    .div52 {
      width: 500px !important;
      left: -14.8px !important;
    }

    .div55 {
      width: 500px !important;
      left: -14px !important;
    }

    .wrapper .sector3 {
      left: -15%;
      top: 0;
      height: 50%;
      width: 550px;
      margin-left: -300px;
      margin-top: 0px;
    }
  }

  @media only screen and (max-width: 376px) {
    .div73 {
      position: absolute;
      top: 75%;
      left: 40%;
    }

    .div74 {
      position: absolute;
      top: 58%;
      left: 52%;
    }

    .div75 {
      position: absolute !important;
      top: 65% !important;
      left: 50% !important;
    }

    .div76 {
      position: absolute;
      top: 68%;
      left: 60%;
    }

    .div52 {
      width: 400px !important;
      left: 74.8px !important;
    }

    .ddiv52 {
      position: absolute;
      top: 48%;
      left: 42%;
    }

    .div53 {
      position: absolute;
      top: 60%;
      left: 60%;
    }

    .ddiv55 {
      position: absolute;
      top: 68%;
      left: 62%;
    }

    .div32 {
      position: absolute;
      top: 50%;
      left: 70%;
    }

    .div33 {
      position: absolute;
      top: 40%;
      left: 50%;
    }

    .wrapper .sector6 {
      left: 20%;
      top: 0%;
      height: 50%;
      width: 400px;
      margin-left: -300px;
      margin-top: 0px;
    }

    .div66 {
      position: absolute;
      top: 70%;
      left: 58%;
      width: 70px;
    }

    .div64 {
      position: absolute;
      top: 60%;
      left: 53.5%;
    }

    .div63 {
      position: absolute;
      top: 60%;
      left: 55%;
    }

    .div61 {
      position: absolute;
      top: 55%;
      left: 58%;
    }
  }
}
/****CircleMenu****/

@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-radius: 12px !important;
}

/* Range Slider */
.range-slider__thumb {
  height: 15px !important;
  width: 15px !important;
}

/* .range-slider {
  background-color: red !important;
} */

/* .range-slider__range {
  background-color: green !important;
} */
