@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .specialtext {
    text-shadow:
      2px 0 #000000,
      -2px 0 #000000,
      0 2px #000000,
      0 -2px #000000,
      1px 1px #000000,
      -1px -1px #000000,
      1px -1px #000000,
      -1px 1px #000000;
  }

  .specialtextWhite {
    text-shadow:
      2px 0 #fff,
      -2px 0 #fff,
      0 2px #fff,
      0 -2px #fff,
      1px 1px #fff,
      -1px -1px #fff,
      1px -1px #fff,
      -1px 1px #fff;
  }
}
